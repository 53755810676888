html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-size: 18px;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: blanchedalmond;
}

.flash-color {
  display: block;
  width: 650px;
  margin: 0 auto;
  color: firebrick;
  height: 35px;
  border: 2px dashed lawngreen;
  border-radius: 5px;
  padding: 3px;
  line-height: 45px;
  font-size: 1.2rem;
  -webkit-animation: flash linear 0.75s infinite;
  animation: flash linear 0.75s infinite;
}
@media only screen and (max-width: 1000px) {
  .flash-color {
    width: 100%;
  }
}

@-webkit-keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
